<template>
  <v-dialog
    v-model="isDialogOpened"
    max-width="800"
    @click:outside="isDialogOpened = false"
  >
    <template #activator="{ on }">
      <v-btn v-on="on">
        Save
        <v-icon class="e-ml-2">fas fa-save</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Save Query</span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="title"
          label="Query Name"
          clearable
          class="caption"
        >
          <template #label>
            <div class="caption">Query Name</div>
          </template>
        </v-text-field>
        <v-textarea
          v-model="description"
          label="Description"
          max="256"
          class="caption"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="onCancel"> Cancel </v-btn>
        <v-btn color="primary" :disabled="title.length < 4" @click="saveQuery">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { useSmartSearchStore } from "@/stores/smartSearch"
import { mapStores } from "pinia"
import { SmartSearchQueryComponentData } from "@evercam/shared/types"
import { useSiteAnalyticsStore } from "@/stores/siteAnalytics"

export default Vue.extend({
  name: "SaveQueryDialog",
  props: {
    components: {
      type: Array as PropType<SmartSearchQueryComponentData[]>,
      required: true,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    author: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      title: "",
      description: "",
      isDialogOpened: false,
    }
  },
  computed: {
    ...mapStores(useSmartSearchStore, useSiteAnalyticsStore),
  },
  methods: {
    saveQuery() {
      this.smartSearchStore.saveQuery({
        title: this.title,
        description: this.description,
        author: this.author,
        totalItems: this.totalItems,
        createdAt: new Date().toISOString(),
        components: this.components,
        cameraExid: this.siteAnalyticsStore.selectedCamera.exid,
      })
      this.isDialogOpened = false
      this.smartSearchStore.loadQueries()
    },
    onCancel() {
      this.title = ""
      this.description = ""
      this.isDialogOpened = false
    },
  },
})
</script>
