<template>
  <div class="e-w-full e-p-4">
    <ESchedulePicker
      :value="value"
      :schedule-type="ScheduleType.WorkingHours"
      @input="updateValue"
    />
    <div class="e-w-full e-flex e-justify-end e-mt-4">
      <v-btn small color="primary" dense flat @click="saveTimeSchedule"
        >Create</v-btn
      >
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import { ScheduleType } from "@evercam/ui"
export default Vue.extend({
  name: "SmartSearchScheduleTimeSelector",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentValue: this.value || {},
    }
  },
  computed: {
    ScheduleType() {
      return ScheduleType
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(this.currentValue)) {
          this.currentValue = newValue || {}
        }
      },
      deep: true,
    },
  },
  methods: {
    updateValue(value) {
      this.currentValue = value
    },
    saveTimeSchedule() {
      this.$emit("change", this.currentValue)
    },
  },
})
</script>
