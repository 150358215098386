<template>
  <v-sheet class="smart-search__option-selector">
    <div
      v-if="type === SmartSearchQueryComponentType.Condition"
      class="e-flex e-gap-3 e-flex-wrap e-p-4"
    >
      <SmartSearchOption
        v-for="option in conditions"
        :key="option"
        :value="option"
        :type="type"
        selectable
        :highlighted="isOptionHighlighted(option)"
        @change="onOptionSelected"
      />
    </div>

    <div
      v-else-if="type === SmartSearchQueryComponentType.Object"
      class="e-flex e-gap-3 e-flex-wrap e-p-4"
    >
      <SmartSearchOption
        v-for="label in detectionLabels"
        :key="label"
        :value="label"
        selectable
        :type="SmartSearchQueryComponentType.Object"
        :highlighted="selectedValue === label"
        @change="onOptionSelected"
      />
    </div>

    <div v-else-if="isTimeComponent" class="e-flex e-gap-3 e-flex-wrap e-p-4">
      <SmartSearchScheduleTimeSelector
        :value="selectedValue || {}"
        @change="onOptionSelected"
      />
    </div>

    <div v-else-if="isAreaComponent" class="e-flex e-gap-3 e-flex-wrap e-p-4">
      <SmartSearchAreaSelector
        :token="token"
        :camera="camera"
        :value="selectedValue"
        @change="onOptionSelected"
      />
    </div>

    <div
      v-if="selectedCondition"
      class="smart-search__option-selector__values-container"
    >
      <v-divider class="w-100"></v-divider>
      <div
        v-if="selectedCondition === SmartSearchCondition.Intersects"
        class="e-flex e-gap-3 e-flex-wrap e-p-4"
      >
        <SmartSearchOption
          v-for="label in detectionLabels"
          :key="label"
          :value="label"
          selectable
          :type="SmartSearchQueryComponentType.Object"
          :highlighted="selectedValue === label"
          @change="onConditionValueSelected"
        />
      </div>
      <div
        v-else-if="selectedCondition === SmartSearchCondition.InArea"
        class="e-flex e-gap-3 e-flex-wrap e-p-4"
      >
        <SmartSearchAreaSelector
          :token="token"
          :camera="camera"
          :value="selectedValue"
          @change="onConditionValueSelected"
        />
      </div>
      <div
        v-else-if="selectedCondition === SmartSearchCondition.Time"
        class="e-flex e-gap-3 e-flex-wrap e-p-4"
      >
        <SmartSearchScheduleTimeSelector
          :value="selectedValue || {}"
          @change="onConditionValueSelected"
        />
      </div>
    </div>
  </v-sheet>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import {
  Camera,
  DetectionLabel,
  SmartSearchCondition,
  SmartSearchQueryComponentType,
} from "@evercam/shared/types"
import SmartSearchOption from "@/components/siteAnalytics/SmartSearchOption.vue"
import SmartSearchAreaSelector from "@/components/siteAnalytics/SmartSearchAreaSelector.vue"
import SmartSearchScheduleTimeSelector from "@/components/siteAnalytics/SmartSearchScheduleTimeSelector.vue"

export default Vue.extend({
  name: "SmartSearchOptionSelector",
  components: {
    SmartSearchOption,
    SmartSearchAreaSelector,
    SmartSearchScheduleTimeSelector,
  },
  props: {
    type: {
      type: String as PropType<SmartSearchQueryComponentType>,
      required: true,
    },
    selectedValue: {
      type: [String, Object, Array] as PropType<DetectionLabel | any>,
      default: () => null,
    },
    token: {
      type: String,
      default: "",
    },
    camera: {
      type: Object as PropType<Camera>,
      required: true,
    },
  },
  data() {
    return {
      selectedCondition: null as SmartSearchCondition | null,
    }
  },
  computed: {
    SmartSearchQueryComponentType() {
      return SmartSearchQueryComponentType
    },
    SmartSearchCondition() {
      return SmartSearchCondition
    },
    options(): SmartSearchCondition[] | DetectionLabel[] {
      if (this.type === SmartSearchQueryComponentType.Object) {
        return this.detectionLabels
      } else {
        return this.conditions
      }
    },
    conditions(): SmartSearchCondition[] {
      return [
        SmartSearchCondition.Intersects,
        SmartSearchCondition.InArea,
        SmartSearchCondition.Time,
      ]
    },
    detectionLabels(): DetectionLabel[] {
      return [
        DetectionLabel.ArticulatedDumper,
        DetectionLabel.BackhoeLoader,
        DetectionLabel.CompactExcavator,
        DetectionLabel.ConcretePump,
        DetectionLabel.Dumper,
        DetectionLabel.Excavator,
        DetectionLabel.FlatbedSemiTrailer,
        DetectionLabel.IndustrialTruck,
        DetectionLabel.Loader,
        DetectionLabel.Mewps,
        DetectionLabel.MobileCrane,
        DetectionLabel.MobileCrusher,
        DetectionLabel.PileDrillingRig,
        DetectionLabel.RoadVehicle,
        DetectionLabel.Roller,
        DetectionLabel.SemiTrailer,
        DetectionLabel.SkidSteerLoader,
        DetectionLabel.SkipTruck,
        DetectionLabel.SmallTruck,
        DetectionLabel.TankTruck,
        DetectionLabel.TeleHandler,
        DetectionLabel.TippingTruck,
        DetectionLabel.TowerCrane,
        DetectionLabel.TractorDozer,
        DetectionLabel.TruckHead,
        DetectionLabel.TruckMixer,
        DetectionLabel.Worker,
      ]
    },
    isTimeComponent(): boolean {
      return this.type === SmartSearchQueryComponentType.Time
    },
    isAreaComponent(): boolean {
      return this.type === SmartSearchQueryComponentType.Area
    },
    isObjectComponent(): boolean {
      return this.type === SmartSearchQueryComponentType.Object
    },
  },
  methods: {
    onOptionSelected(option: SmartSearchCondition | DetectionLabel | any) {
      if (this.type !== SmartSearchQueryComponentType.Condition) {
        this.$emit("option-selected", option)

        return
      }

      this.selectedCondition = option as SmartSearchCondition
    },
    onConditionValueSelected(value: unknown) {
      this.$emit("option-selected", {
        condition: this.selectedCondition,
        value: value,
      })
      this.selectedCondition = null
    },
    isOptionHighlighted(
      option: SmartSearchCondition | DetectionLabel
    ): boolean {
      if (this.type === SmartSearchQueryComponentType.Object) {
        return this.selectedValue === option
      }

      if (this.type === SmartSearchQueryComponentType.Condition) {
        if (this.selectedValue === option) {
          return true
        }

        if (
          this.selectedValue &&
          typeof this.selectedValue === "object" &&
          "condition" in this.selectedValue
        ) {
          return this.selectedValue.condition === option
        }

        if (this.selectedCondition === option) {
          return true
        }
      }

      return false
    },
  },
})
</script>
