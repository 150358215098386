<template>
  <div
    ref="container"
    class="smart-search__area-selector"
    style="height: 500px"
    @mousemove="handleMouseMove"
    @mouseup="handleMouseUp"
  >
    <img
      class="smart-search__area-selector__img e-border-solid e-border-[1px] e-border-gray-200 e-rounded-lg"
      :src="thumbnailUrl"
      :alt="camera.name"
      draggable="false"
      @load="onImageLoad"
    />

    <svg
      class="polygon-overlay"
      :width="imageWidth"
      :height="imageHeight"
      @dblclick="completePolygon"
    >
      <polygon
        v-if="points.length > 2"
        :points="polygonPoints"
        class="polygon-shape"
      />

      <line
        v-else-if="points.length === 2"
        :x1="points[0].x"
        :y1="points[0].y"
        :x2="points[1].x"
        :y2="points[1].y"
        class="polygon-shape"
      />

      <circle
        v-for="(point, index) in points"
        :key="index"
        :cx="point.x"
        :cy="point.y"
        r="6"
        class="polygon-point"
        @mousedown.stop="startDraggingPoint($event, index)"
      />
    </svg>

    <div v-if="points.length > 2" class="selection-box__save">
      <v-btn
        icon
        color="primary"
        class="selection-box__save-button"
        @mousedown="saveSelection"
        @click.stop="saveSelection"
      >
        <v-icon color="white">fas fa-check</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { Camera, Point } from "@evercam/shared/types"

export default Vue.extend({
  name: "SmartSearchAreaSelector",
  props: {
    camera: {
      type: Object as PropType<Camera>,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    value: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
  },
  data() {
    return {
      points: [] as Point[],
      imageWidth: 0,
      imageHeight: 0,
      lastClickTime: 0,
      draggingPointIndex: null as number | null,
      isDraggingPoint: false,
    }
  },
  computed: {
    thumbnailUrl() {
      const snapshotsEndpoint = `${this.$config.public.apiURL}/cameras/${this.camera.exid}/recordings/snapshots`
      const processedTimestamp = this.$moment()
        .tz(this.camera.timezone)
        .subtract(1, "day")
        .hour(11)
        .format()

      return `${snapshotsEndpoint}/${processedTimestamp}/nearest?view=true&authorization=${this.token}`
    },
    polygonPoints(): string {
      return this.points.map((point) => `${point.x},${point.y}`).join(" ")
    },
  },
  mounted() {
    if (this.value && this.value.length > 0) {
      this.loadExistingPolygon(this.value)
    }
  },
  methods: {
    onImageLoad(event: Event) {
      const img = event.target as HTMLImageElement
      this.imageWidth = img.width
      this.imageHeight = img.height

      if (this.value && this.value.length > 0) {
        this.loadExistingPolygon(this.value)
      }
    },
    insertPoint({ x, y }: Point) {
      if (this.points.length < 3) {
        this.points.push({
          x,
          y,
        })

        return
      }

      const edgeDistances = []
      for (let i = 0; i < this.points.length; i++) {
        const p1 = this.points[i]
        const p2 = this.points[(i + 1) % this.points.length]

        const distance = this.distanceToLineSegment(p1, p2, { x, y })

        edgeDistances.push({
          index: i,
          distance: distance,
        })
      }

      edgeDistances.sort((a, b) => a.distance - b.distance)

      const closestEdgeIndex = edgeDistances[0].index
      this.points.splice(closestEdgeIndex + 1, 0, { x, y })
    },
    distanceToLineSegment(p1: Point, p2: Point, p: Point): number {
      const { x: x1, y: y1 } = p1
      const { x: x2, y: y2 } = p2
      const { x, y } = p

      const lengthSquared = (x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1)
      if (lengthSquared === 0) {
        return Math.sqrt((x - x1) * (x - x1) + (y - y1) * (y - y1))
      }

      let t = ((x - x1) * (x2 - x1) + (y - y1) * (y2 - y1)) / lengthSquared
      t = Math.max(0, Math.min(1, t))

      const projX = x1 + t * (x2 - x1)
      const projY = y1 + t * (y2 - y1)

      return Math.sqrt((x - projX) * (x - projX) + (y - projY) * (y - projY))
    },
    startDraggingPoint(event: MouseEvent, index: number) {
      event.stopPropagation()
      this.draggingPointIndex = index
      this.isDraggingPoint = true
    },

    handleMouseMove(event: MouseEvent) {
      const container = this.$refs.container as HTMLElement
      const rect = container.getBoundingClientRect()

      const x = event.clientX - rect.left
      const y = event.clientY - rect.top

      if (
        this.isDraggingPoint &&
        this.draggingPointIndex >= 0 &&
        this.draggingPointIndex < this.points.length
      ) {
        this.$set(this.points, this.draggingPointIndex, {
          x,
          y,
        })
      }
    },
    handleMouseUp(event: MouseEvent) {
      if (this.isDraggingPoint) {
        this.isDraggingPoint = false
        this.draggingPointIndex = null

        return
      }

      const container = this.$refs.container as HTMLElement
      const rect = container.getBoundingClientRect()

      const x = event.clientX - rect.left
      const y = event.clientY - rect.top

      this.insertPoint({ x, y })
    },
    completePolygon() {
      if (this.points.length < 3) {
        return
      }
    },
    loadExistingPolygon(polygonArray: number[]) {
      if (!this.imageWidth || !this.imageHeight) {
        return
      }

      this.points = []

      for (let i = 0; i < polygonArray.length; i += 2) {
        if (i + 1 < polygonArray.length) {
          const x = polygonArray[i] * this.imageWidth
          const y = polygonArray[i + 1] * this.imageHeight
          this.points.push({ x, y })
        }
      }
    },
    saveSelection() {
      if (this.points.length < 3) {
        return
      }

      const normalizedPoints = []
      for (const point of this.points) {
        normalizedPoints.push(point.x / this.imageWidth)
        normalizedPoints.push(point.y / this.imageHeight)
      }

      if (
        normalizedPoints[0] !== normalizedPoints[normalizedPoints.length - 2] ||
        normalizedPoints[1] !== normalizedPoints[normalizedPoints.length - 1]
      ) {
        normalizedPoints.push(normalizedPoints[0])
        normalizedPoints.push(normalizedPoints[1])
      }

      this.$emit("change", normalizedPoints)
    },
  },
})
</script>

<style lang="scss">
.smart-search__area-selector {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__img {
    user-select: none;
    -webkit-user-drag: none;
    pointer-events: none;
    display: block;
    min-width: 100%;
    object-fit: cover;
    object-position: center;
    position: relative;
    z-index: 2;
  }
}

.polygon-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  pointer-events: none;
}

.polygon-shape {
  fill: #029eff22;
  stroke: #029eff;
  stroke-width: 2;
  stroke-dasharray: 5, 5;
  animation: border-dance 1s infinite linear;
}

.drawing-line {
  stroke: #029eff;
  stroke-width: 2;
  stroke-dasharray: 5, 5;
}

.polygon-point {
  fill: #029eff;
  stroke: white;
  stroke-width: 2;
  cursor: move;
  pointer-events: all;
}

$border-color: #029eff;
.selection-box__save {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
  pointer-events: auto;
}

.selection-box__save-button {
  background-color: #029eff;
  color: white;
  padding: 6px 16px;
  transition: background-color 0.2s;

  &:hover {
    background-color: darken(#029eff, 10%);
  }

  &:active {
    background-color: darken(#029eff, 15%);
  }
}

@keyframes border-dance {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 20;
  }
}
</style>
