<template>
  <v-sheet class="e-flex e-flex-col">
    <div class="smart-search e-p-8 e-pb-4 e-flex e-flex-col">
      <div class="e-flex e-gap-3 e-flex-wrap">
        <SmartSearchQuery
          v-if="components.length"
          :components="components"
          @connect-right="connectComponent"
          @change="updateComponent"
          @delete-component="handleComponentDelete"
        />

        <v-btn v-else color="primary" @click="initializeQuery">
          Create query
          <v-icon right>fa-plus</v-icon>
        </v-btn>
      </div>

      <v-dialog
        v-model="smartSearchStore.optionSelectorDialog"
        max-width="940px"
      >
        <v-card>
          <v-card-title>
            <span>{{ smartSearchStore.optionSelectorTitle }}</span>
          </v-card-title>
          <v-divider />
          <SmartSearchOptionSelector
            :type="smartSearchStore.optionSelectorType"
            :selected-value="smartSearchStore.selectedOption?.value"
            :camera="siteAnalyticsStore.selectedCamera"
            :token="accountStore.token"
            @option-selected="smartSearchStore.onOptionSelected"
          />
        </v-card>
      </v-dialog>
      <div class="e-flex e-w-full e-justify-end e-items-center e-gap-2 e-mt-4">
        <v-btn v-if="components.length" @click="onClearQuery">
          Clear
          <v-icon class="e-ml-2">fa-trash</v-icon>
        </v-btn>

        <!-- Save Query Dialog -->
        <SmartSearchSaveQueryDialog
          v-if="components.length > 1"
          :components="components"
          :total-items="total"
          :author="currentUserEmail"
        />
        <v-btn
          color="primary"
          :loading="isExecutingQuery"
          :disabled="components.length < 2"
          @click="executeQuery"
          >Execute <v-icon class="e-ml-2">fa-search</v-icon></v-btn
        >
      </div>
    </div>
    <v-divider />
    <SmartSearchResultsTable
      :key="items.length"
      :is-loading="isExecutingQuery"
      :items="items"
      :items-per-page="limit"
      :page="page"
      :total-items="total"
      @page-change="onPageChange"
    />
  </v-sheet>
</template>

<script lang="ts">
import Vue from "vue"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { useSiteAnalyticsStore } from "@/stores/siteAnalytics"
import { useSmartSearchStore } from "@/stores/smartSearch"
import { PERMISSIONS } from "@/constants/permissions"
import SmartSearchQuery from "@/components/siteAnalytics/SmartSearchQuery.vue"
import SmartSearchOptionSelector from "@/components/siteAnalytics/SmartSearchOptionSelector.vue"
import SmartSearchResultsTable from "@/components/siteAnalytics/SmartSearchResultsTable.vue"
import SmartSearchSaveQueryDialog from "@/components/siteAnalytics/SmartSearchSaveQueryDialog.vue"
import {
  SmartSearchCondition,
  type SmartSearchQueryComponentData,
  SmartSearchQueryComponentType,
  SmartSearchQueryReturnType,
} from "@evercam/shared/types"
import { generateUuid } from "@evercam/shared/utils"
import { convertToSmartSearchQuery } from "~~/components/siteAnalytics/smart-search-helpers"
import { AiApi } from "@evercam/shared/api/aiApi"
import { useBreadcrumbStore } from "~/stores/breadcrumb"

export default Vue.extend({
  meta: {
    requiredPermissions: [PERMISSIONS.SITE_ANALYTICS.VIEW],
  },
  name: "SiteAnalyticsSmartSearch",
  components: {
    SmartSearchOptionSelector,
    SmartSearchQuery,
    SmartSearchResultsTable,
    SmartSearchSaveQueryDialog,
  },
  async asyncData({ params }) {
    const cameraExid = params.camera_exid
    const siteAnalyticsStore = useSiteAnalyticsStore()

    if (!cameraExid) {
      return
    } else if (cameraExid !== siteAnalyticsStore.selectedCamera?.exid) {
      await siteAnalyticsStore.selectCamera(cameraExid)
    }

    const breadcrumbStore = useBreadcrumbStore()
    breadcrumbStore.breadcrumbs = [
      {
        name: "Home",
        href: "/",
        icon: "fa-house",
      },
      {
        name: "Site Analytics",
        href: "/site-analytics",
        icon: "fa-chart-simple",
      },
      {
        name: `${siteAnalyticsStore.selectedCamera.name} (${siteAnalyticsStore.selectedCamera.exid})`,
        href: `/site-analytics/${siteAnalyticsStore.selectedCamera.exid}`,
        icon: "fa-camera",
      },
      {
        name: "Smart Search",
        icon: "fa-search",
      },
    ]
  },
  data() {
    return {
      isExecutingQuery: false,
      page: 1,
      limit: 20,
      total: 0,
      from: 0,
      to: 0,
      items: [],
      components: [] as SmartSearchQueryComponentData[],
      returnType: SmartSearchQueryReturnType.FirstLastSeen,
    }
  },
  head() {
    return {
      title: `Smart-Search | ${
        useSiteAnalyticsStore().selectedCamera?.name || "Evercam"
      }`,
    }
  },
  computed: {
    ...mapStores(useAccountStore, useSiteAnalyticsStore, useSmartSearchStore),
    SmartSearchQueryReturnType() {
      return SmartSearchQueryReturnType
    },
    currentUserEmail(): string {
      return useAccountStore().email
    },
  },
  methods: {
    async onPageChange(value: number) {
      this.items = []
      this.total = 0
      this.page = value
      await this.executeQuery()
    },
    resetData() {
      this.items = []
      this.total = 0
      this.page = 1
      this.from = 0
      this.to = 0
    },

    async executeQuery() {
      try {
        this.items = []
        this.isExecutingQuery = true
        const payload = convertToSmartSearchQuery({
          components: this.components,
          options: {
            returnType: this.returnType,
          },
        })
        const query = {
          page: this.page,
          pageSize: this.limit,
        }

        const data = await AiApi.siteAnalytics.smartSearch({
          cameraExid: this.siteAnalyticsStore.selectedCameraExid,
          query,
          payload,
        })

        if ("detail" in data) {
          throw new Error(data.detail)
        } else {
          const { page, pageSize, totalCount, results } = data
          this.items = results
          this.total = totalCount
          this.from = (page - 1) * pageSize + 1
          this.to = Math.min(page * pageSize, totalCount)
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.isExecutingQuery = false
        this.smartSearchStore.returnType = this.returnType
      }
    },
    initializeQuery() {
      this.smartSearchStore.openOptionSelectorDialog({
        optionSelectorType: SmartSearchQueryComponentType.Object,
        onOptionSelected: (value) =>
          this.addComponents([
            {
              type: SmartSearchQueryComponentType.Object,
              parts: [
                {
                  type: SmartSearchQueryComponentType.Object,
                  value,
                },
              ],
            },
          ]),
      })
    },
    addComponents(components: Omit<SmartSearchQueryComponentData, "id">[]) {
      const newComponents = components.map((c) => {
        const id = generateUuid()

        return {
          id,
          type: c.type,
          parts: c.parts.map((c) => ({
            ...c,
            id,
          })),
        }
      })
      console.log("Adding components: ", newComponents)
      this.components = this.components.concat(newComponents)

      this.$setTimeout(() => {
        console.log(
          "Actual components: ",
          JSON.parse(JSON.stringify(this.components))
        )
      }, 500)
    },
    connectComponent() {
      this.smartSearchStore.openOptionSelectorDialog({
        optionSelectorType: SmartSearchQueryComponentType.Condition,
        onOptionSelected: (value) => {
          let successorType: SmartSearchQueryComponentType
          if (typeof value === "object" && "condition" in value) {
            switch (value.condition) {
              case SmartSearchCondition.Intersects:
                successorType = SmartSearchQueryComponentType.Object
                break
              case SmartSearchCondition.InArea:
                successorType = SmartSearchQueryComponentType.Area
                break
              case SmartSearchCondition.Time:
                successorType = SmartSearchQueryComponentType.Time
                break
            }

            this.addComponents([
              {
                type: SmartSearchQueryComponentType.Condition,
                parts: [
                  {
                    type: SmartSearchQueryComponentType.Condition,
                    value: value.condition,
                  },
                ],
              },
              {
                type: successorType,
                parts: [
                  {
                    type: successorType,
                    value: value.value,
                  },
                ],
              },
            ])
          } else {
            this.addComponents([
              {
                type: SmartSearchQueryComponentType.Condition,
                parts: [
                  {
                    type: SmartSearchQueryComponentType.Condition,
                    value,
                  },
                ],
              },
            ])
          }
        },
      })
    },
    updateComponent(component: SmartSearchQueryComponentData) {
      const index = this.components.findIndex((c) => c.id === component.id)
      if (index === -1) {
        return
      }

      const oldComponent = this.components[index]

      if (
        component.type !== SmartSearchQueryComponentType.Condition &&
        typeof component.parts[0].value === "object" &&
        component.parts[0].value !== null &&
        "condition" in component.parts[0].value
      ) {
        const conditionValue = component.parts[0].value.condition
        const valueData = component.parts[0].value.value

        let conditionComponentIndex = -1

        if (
          index > 0 &&
          this.components[index - 1].type ===
            SmartSearchQueryComponentType.Condition
        ) {
          conditionComponentIndex = index - 1
        } else {
          conditionComponentIndex = this.components.findIndex(
            (c, i) =>
              i < index && c.type === SmartSearchQueryComponentType.Condition
          )
        }

        if (conditionComponentIndex >= 0) {
          const conditionComponent = this.components[conditionComponentIndex]
          const updatedConditionComponent = {
            ...conditionComponent,
            parts: [
              {
                ...conditionComponent.parts[0],
                value: conditionValue,
              },
            ],
          }

          let correctComponentType: SmartSearchQueryComponentType
          let correctOptionType: SmartSearchQueryComponentType

          if (conditionValue === SmartSearchCondition.Intersects) {
            correctComponentType = SmartSearchQueryComponentType.Object
            correctOptionType = SmartSearchQueryComponentType.Object
          } else if (conditionValue === SmartSearchCondition.InArea) {
            correctComponentType = SmartSearchQueryComponentType.Area
            correctOptionType = SmartSearchQueryComponentType.Area
          } else if (conditionValue === SmartSearchCondition.Time) {
            correctComponentType = SmartSearchQueryComponentType.Time
            correctOptionType = SmartSearchQueryComponentType.Time
          }

          const valueComponent = {
            id: component.id,
            type: correctComponentType,
            parts: [
              {
                id: component.id,
                type: correctOptionType,
                value: valueData,
              },
            ],
          }

          const componentsBeforeCondition = this.components.slice(
            0,
            conditionComponentIndex
          )

          const componentsAfter = this.components.slice(index + 1)

          this.components = [
            ...componentsBeforeCondition,
            updatedConditionComponent,
            valueComponent,
            ...componentsAfter,
          ]

          return
        }
      }

      if (oldComponent.type === SmartSearchQueryComponentType.Condition) {
        const oldConditionValue =
          typeof oldComponent.parts[0].value === "string"
            ? oldComponent.parts[0].value
            : oldComponent.parts[0].value?.condition

        const newConditionValue =
          typeof component.parts[0].value === "string"
            ? component.parts[0].value
            : component.parts[0].value?.condition

        const newValueData =
          typeof component.parts[0].value === "object" &&
          component.parts[0].value !== null &&
          "value" in component.parts[0].value
            ? component.parts[0].value.value
            : null

        if (oldConditionValue !== newConditionValue) {
          let nextComponentType: SmartSearchQueryComponentType
          let nextOptionType: SmartSearchQueryComponentType

          const actualCondition =
            typeof newConditionValue === "string"
              ? newConditionValue
              : newConditionValue

          if (actualCondition === SmartSearchCondition.Intersects) {
            nextComponentType = SmartSearchQueryComponentType.Object
            nextOptionType = SmartSearchQueryComponentType.Object
          } else if (actualCondition === SmartSearchCondition.InArea) {
            nextComponentType = SmartSearchQueryComponentType.Area
            nextOptionType = SmartSearchQueryComponentType.Area
          } else if (actualCondition === SmartSearchCondition.Time) {
            nextComponentType = SmartSearchQueryComponentType.Time
            nextOptionType = SmartSearchQueryComponentType.Time
          }

          const componentsBeforeCondition = this.components.slice(0, index)

          let updatedCondition

          if (
            typeof component.parts[0].value === "object" &&
            component.parts[0].value !== null &&
            "condition" in component.parts[0].value
          ) {
            updatedCondition = {
              ...component,
              parts: [
                {
                  ...component.parts[0],
                  value: component.parts[0].value.condition,
                },
              ],
            }
          } else {
            updatedCondition = component
          }

          const hasNextComponent = index + 1 < this.components.length
          let nextComponent: SmartSearchQueryComponentData

          if (hasNextComponent) {
            const existingNextComponent = this.components[index + 1]

            if (existingNextComponent.type === nextComponentType) {
              if (newValueData !== null) {
                nextComponent = {
                  ...existingNextComponent,
                  parts: [
                    {
                      ...existingNextComponent.parts[0],
                      value: newValueData,
                    },
                  ],
                }
              } else {
                nextComponent = existingNextComponent
              }
            } else {
              const nextId = generateUuid()
              nextComponent = {
                id: nextId,
                type: nextComponentType,
                parts: [
                  {
                    id: nextId,
                    type: nextOptionType,
                    value:
                      newValueData !== null
                        ? newValueData
                        : this.getDefaultValueForType(nextComponentType),
                  },
                ],
              }
            }
          } else {
            const nextId = generateUuid()
            nextComponent = {
              id: nextId,
              type: nextComponentType,
              parts: [
                {
                  id: nextId,
                  type: nextOptionType,
                  value:
                    newValueData !== null
                      ? newValueData
                      : this.getDefaultValueForType(nextComponentType),
                },
              ],
            }
          }

          const componentsAfterNext = hasNextComponent
            ? this.components.slice(index + 2)
            : []

          this.components = [
            ...componentsBeforeCondition,
            updatedCondition,
            nextComponent,
            ...componentsAfterNext,
          ]
        } else {
          this.components.splice(index, 1, component)
        }
      } else {
        this.components.splice(index, 1, component)
      }
    },
    getDefaultValueForType(type: SmartSearchQueryComponentType) {
      switch (type) {
        case SmartSearchQueryComponentType.Time:
          return {
            monday: ["08:00-16:00"],
            tuesday: ["08:00-16:00"],
            wednesday: ["08:00-16:00"],
            thursday: ["08:00-16:00"],
            friday: ["08:00-16:00"],
            saturday: [],
            sunday: [],
          }
        case SmartSearchQueryComponentType.Area:
          return [0.1, 0.1, 0.9, 0.9]
        case SmartSearchQueryComponentType.Object:
          return "worker"
        default:
          return null
      }
    },
    handleComponentDelete({ componentId, deleteEntireComponent = false }) {
      const componentIndex = this.components.findIndex(
        (c) => c.id === componentId
      )

      if (componentIndex === -1) {
        console.error("Component not found for deletion:", componentId)

        return
      }

      if (deleteEntireComponent) {
        this.components = this.components.slice(0, componentIndex)

        return
      }

      const componentType = this.components[componentIndex].type

      if (
        (componentType === SmartSearchQueryComponentType.Object ||
          componentType === SmartSearchQueryComponentType.Time ||
          componentType === SmartSearchQueryComponentType.Area) &&
        componentIndex > 0
      ) {
        const previousComponent = this.components[componentIndex - 1]
        if (
          previousComponent.type === SmartSearchQueryComponentType.Condition
        ) {
          this.components = this.components.slice(0, componentIndex - 1)

          return
        }
      }

      this.components = this.components.slice(0, componentIndex)

      console.log(
        "Components after deletion:",
        JSON.parse(JSON.stringify(this.components))
      )
    },
    onClearQuery() {
      this.components = []
      this.resetData()
    },
    onSavedQueryClicked(item) {
      this.components = item.components
    },
  },
})
</script>

<style lang="scss">
.smart-search {
  .e-right-0 {
    right: 0;
  }
}
</style>
